<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="false"
    ></loading>
    <h3>{{ $t('frequencyTitle') }}<br><small>{{ $t('frequencyInstruction') }}</small></h3>
    <div v-if="frequencies">
      <div
        class="btn-group frequencies"
        role="group"
      >
        <button
          v-for="frequency in frequencies"
          :key="frequency.id"
          :class="{active: isSelectedFrequency(frequency.id)}"
          class="btn"
          type="button"
          @click="setFrequency(frequency.id)"
        >
          {{ $t(`frequencyOptions.${camelize(frequency.name)}`) }}
          <strong v-if="frequency.percent > 0">(-{{ frequency.percent }}%)</strong>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';

export default {
  name: 'Frequencies',
  components: {
    Loading,
  },
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    frequencies: {
      type: Array,
      default: () => [],
    },
    selectedFrequency: {
      type: [Number, Boolean],
      default: () => false,
    },
    setFrequency: {
      type: Function,
      required: true,
    },
  },
  methods: {
    camelize(val) {
      if (!val) return '';
      const text = val.replace(/[^\w\s]/gi, '');
      return text.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => (index === 0 ? letter.toLowerCase() : letter.toUpperCase())).replace(/\s+/g, '');
    },
    isSelectedFrequency(frequencyID) {
      if (this.selectedFrequency && frequencyID && frequencyID === this.selectedFrequency) {
        return true;
      }
      return false;
    },
  },
};
</script>
