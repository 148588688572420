<template>
  <div
    :class="{notmobile: !isMobile}"
    class="help"
  >
    <div
      :class="{opened: opened}"
      class="help-content"
      @click="toggleContent()"
    >
      <h5>
        {{ title }}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <circle
            cx="12"
            cy="12"
            r="10"
          />
          <path
            d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"
          />
          <line
            x1="12"
            y1="17"
            x2="12"
            y2="17"
          />
        </svg>
      </h5>
      <div v-html="content"></div>
    </div>

    <div
      v-if="title2 && content2"
      :class="{opened: opened2}"
      class="help-content"
      @click="toggleContent2()"
    >
      <h5>
        {{ title2 }}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <circle
            cx="12"
            cy="12"
            r="10"
          />
          <path
            d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"
          />
          <line
            x1="12"
            y1="17"
            x2="12"
            y2="17"
          />
        </svg>
      </h5>
      <div v-html="content2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Help',
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    title2: {
      type: String,
      required: true,
    },
    content2: {
      type: String,
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      opened: false,
      opened2: false,
    };
  },
  methods: {
    toggleContent() {
      this.opened = !this.opened;
    },
    toggleContent2() {
      this.opened2 = !this.opened2;
    },
  },
};
</script>

