<template>
  <!-- :disabled="!canBook" -->
  <button
    class="btn book-now"
    type="button"
    @click="book"
  >
    {{ $t('bookNow') }}
  </button>
</template>

<script>
export default {
  name: 'BookNow',
  props: {
    canBook: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    book() {
      this.$emit('book');
    },
  },
};
</script>
