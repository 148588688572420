import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VCalendar from 'v-calendar';
import 'v-calendar/lib/v-calendar.min.css';

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/fr';

import './plugins/axios';
import App from './App.vue';
import i18n from './i18n';

Sentry.init({
  dsn: 'https://ff3f33f555c9447995526e2eefb8eee5@sentry.io/1452065',
  integrations: [
    new Integrations.Vue({
      Vue,
      attachProps: true,
    }),
  ],
});

Vue.config.productionTip = false;

Vue.use(Vuelidate);

// Use v-calendar, v-date-picker & v-popover components
Vue.use(VCalendar, {
  firstDayOfWeek: 1,
});

new Vue({
  i18n,
  render: h => h(App),
}).$mount('#app');
