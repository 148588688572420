<template>
  <div>
    <!-- <p><strong v-html="$t('confirmationInstructions')"></strong></p> -->
    <slot></slot>
    <div
      :class="{ attested: confirm}"
      class="attest">
      <label for="confirmation">
        <svg
          v-if="!confirm"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round">
          <rect
            x="3"
            y="3"
            width="18"
            height="18"
            rx="2"
            ry="2"/>
        </svg>
        <svg
          v-if="confirm"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round">
          <polyline points="9 11 12 14 22 4"/>
          <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"/>
        </svg>
        <input
          id="confirmation"
          v-model="confirm"
          type="checkbox"
        >
        <span v-html="$t('attestLabel')"></span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Confirmation',
  data() {
    return {
      confirm: false,
    };
  },
  watch: {
    confirm(val) {
      this.$emit('toggleConfirm', val);
    },
  },
};
</script>
