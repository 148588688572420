import Vue from 'vue';
import axios from 'axios';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/json';

const initConfig = {
  baseURL: process.env.VUE_APP_API_URL || '',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const Axios = axios.create(initConfig);

// Axios.interceptors.request.use(
//   config =>
//     // Do something before request is sent
//     config
//   ,
//   error =>
//     // Do something with request error
//     Promise.reject(error)
//   ,
// );

// // Add a response interceptor
// Axios.interceptors.response.use(
//   response =>
//     // Do something with response data
//     response
//   ,
//   error =>
//     // Do something with response error
//     Promise.reject(error)
//   ,
// );

Plugin.install = () => {
  Vue.axios = Axios;
  window.axios = Axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return Axios;
      },
    },
    $axios: {
      get() {
        return Axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
