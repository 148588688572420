<template>
  <div
    class="success">
    <h2>{{ $t('success') }}</h2>
    <h3>{{ $t('successImportant') }}</h3>
    <p><strong>{{ $t('successSubtitle1') }}</strong></p>
    <p v-html="$t('successText1')"></p>
    <p><strong>{{ $t('successSubtitle2') }}</strong></p>
    <p v-html="$t('successText2')"></p>
    <p><strong>{{ $t('successSubtitle3') }}</strong></p>
    <p v-html="$t('successText3')"></p>
    <p><strong>{{ $t('successSubtitle4') }}</strong></p>
    <p v-html="$t('successText4')"></p>
    <p class="text-center">
      <button
        class="btn"
        type="button"
        @click="bookAgain"
      >{{ $t('bookAgain') }}</button>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Success',
  methods: {
    bookAgain() {
      this.$emit('bookAgain');
    },
  },
};
</script>
