<template>
  <div class="estimate">
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="false"
    ></loading>
    <div class="first">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-home"
      >
        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/>
        <polyline points="9 22 9 12 15 12 15 22"/>
      </svg>
      {{ $t('summary') }}
    </div>

    <div
      v-if="form && form.services && form.services[0] && form.services[0].extras && form.services[0].extras.length > 0"
      class="extra-list"
    >
      <span
        v-for="extra in form.services[0].extras"
        :key="extra.id"
        v-html="extraSelected(extra.id)"
      ></span>
    </div>

    <div v-if="date !== ''">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-calendar"
      >
        <rect
          x="3"
          y="4"
          width="18"
          height="18"
          rx="2"
          ry="2"/>
        <line
          x1="16"
          y1="2"
          x2="16"
          y2="6"/>
        <line
          x1="8"
          y1="2"
          x2="8"
          y2="6"/>
        <line
          x1="3"
          y1="10"
          x2="21"
          y2="10"/>
      </svg>
      {{ date }}
    </div>

    <div v-if="form && form.frequency_id">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-repeat"
      >
        <polyline points="17 1 21 5 17 9"/>
        <path d="M3 11V9a4 4 0 0 1 4-4h14"/>
        <polyline points="7 23 3 19 7 15"/>
        <path d="M21 13v2a4 4 0 0 1-4 4H3"/>
      </svg>
      <span
        v-html="frequencySelected(form.frequency_id)"
      ></span>
    </div>

    <div v-if="estimate && estimate.discount_amount">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-percent"
      >
        <line
          x1="19"
          y1="5"
          x2="5"
          y2="19"/>
        <circle
          cx="6.5"
          cy="6.5"
          r="2.5"/>
        <circle
          cx="17.5"
          cy="17.5"
          r="2.5"/>
      </svg>
      <span>{{ $t('discount') }} </span><strong>&nbsp;-{{ estimate.discount_amount | formatAmount }}</strong>
    </div>

    <div
      v-if="estimate && estimate.total"
      class="next-total">
      <span>{{ $t('todaysTotal') }}</span><span>{{ estimate.total | formatAmount }}</span>
    </div>

    <div
      v-if="estimate && estimate.next_total"
      class="total"
    >
      <strong>{{ $t('futureCleaningFee') }}</strong>
      <strong>{{ estimate.next_total | formatAmount }}</strong>
    </div>


  </div>
</template>

<script>
import moment from 'moment';
import Loading from 'vue-loading-overlay';

import formatAmount from '@/filters/formatAmount';
import formatNumber from '@/filters/formatNumber';

export default {
  name: 'Estimate',
  components: {
    Loading,
  },
  filters: {
    formatNumber,
    formatAmount,
  },
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    estimate: {
      type: Object,
      default: () => null,
    },
    form: {
      type: [Object, Boolean],
      default: () => false,
    },
    frequencies: {
      type: [Array, Boolean],
      default: () => false,
    },
    service: {
      type: [Object, Boolean],
      default: () => false,
    },
  },
  data() {
    return {
      frequency: false,
    };
  },
  computed: {
    date() {
      let d = '';
      if (this.form && this.form.service_date) {
        d = moment(this.form.service_date).locale(this.$i18n.locale === 'fr' ? 'fr' : 'en').format('LLLL');
        d = d[0].toUpperCase() + d.substr(1);
      }
      return d;
    },
  },
  methods: {
    camelize(val) {
      if (!val) return '';
      const text = val.replace(/[^\w\s]/gi, '');
      return text.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => (index === 0 ? letter.toLowerCase() : letter.toUpperCase())).replace(/\s+/g, '');
    },
    moment(param) {
      if (param) {
        return moment(param);
      }
      return moment();
    },
    extraSelected(id) {
      let ext = false;
      if (this.service && this.service.extras && id) {
        this.service.extras.forEach((extra) => {
          if (extra.id === id) {
            let price = extra.price * 1;
            if (extra.discount_by_frequency) {
              price *= this.frequencyDiscountPercentage();
            }
            ext = `${this.$t(`extrasOptions.${this.camelize(extra.name)}`)} <strong>+${this.$options.filters.formatAmount(price)}</strong>`;
          }
        });
      }
      return ext;
    },
    frequencySelected(id) {
      let freq = '';
      if (this.frequencies && id) {
        this.frequencies.forEach((frequency) => {
          if (frequency.id === id) {
            let percent = '';
            if (frequency.percent > 0) {
              percent = ` <strong>-${frequency.percent}%</strong>`;
            }
            freq = `${this.$t(`frequencyOptions.${this.camelize(frequency.name)}`)}${percent}`;
          }
        });
      }
      return freq;
    },

    frequencyDiscountPercentage() {
      let freq = 100;
      if (this.frequencies && this.form.frequency_id) {
        this.frequencies.forEach((frequency) => {
          if (frequency.id === this.form.frequency_id) {
            let percent = 0;
            if (frequency.percent > 0) {
              percent = frequency.percent * 1;
            }
            freq -= percent;
          }
        });
      }
      return freq / 100;
    },
  },
};
</script>

