<template>
  <div class="app">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      class="app-loading"
    ></loading>

    <form>
      <div
        class="step-wrapper"
      >
        <transition
          :name="transitionSide"
          mode="out-in"
        >
          <div
            v-if="step === 0"
            :key="0"
            class="step"
          >
            <div class="app-container">
              <div class="form-wrapper">
                <div class="form">
                  <div
                    v-if="form.zip"
                    class="mobile-location"
                  >
                    <span><strong v-if="locationName">{{ locationName }}</strong>&nbsp;<small>({{ formattedZip }})</small></span>
                    <button
                      class="btn btn-link"
                      type="button"
                      @click="changeLocation"
                    >
                      {{ $t('changer') }}
                    </button>
                  </div>
                  <h1>{{ $t('title') }}</h1>

                  <div class="form-section">
                    <loading
                      :active.sync="servicesLoading"
                      :can-cancel="false"
                      :is-full-page="false"
                    ></loading>
                    <h3>{{ $t('dimensions') }} <small>{{ $t('excluantSousSol') }}</small></h3>
                    <p
                      v-if="showErrors.indexOf('service') > -1"
                      class="error-section service"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-alert-triangle"
                      >
                        <path
                          d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
                        <line
                          x1="12"
                          y1="9"
                          x2="12"
                          y2="13" />
                        <line
                          x1="12"
                          y1="17"
                          x2="12"
                          y2="17" />
                      </svg>
                      {{ $t('serviceError') }}
                    </p>
                    <div
                      v-if="services && services.length > 0"
                      class="services">
                      <div class="field form-group">
                        <select
                          id="service"
                          v-model="selectedServiceID"
                          class="form-control"
                        >
                          <option
                            v-for="service in services"
                            :key="service.id"
                            :value="service.id"
                          >{{ $t(`bedroomsOptions.${camelize(service.name)}`) }}</option>
                        </select>
                        <label for="service">{{ $t('bedrooms') }}</label>
                      </div>
                      <template
                        v-if="service && service.pricing_parameters && service.pricing_parameters.length > 0"
                      >
                        <div
                          v-for="param in service.pricing_parameters"
                          :key="param.id"
                          class="field form-group"
                        >
                          <select
                            :id="`pricing_parameters[${param.id}]`"
                            :value="pricingParameters && pricingParameters[param.id]"
                            class="form-control"
                            @change="setPricingParameters($event, param.id)"
                          >
                            <option
                              v-for="option in priceParametersOptions(param.id)"
                              :key="option"
                              :value="option"
                            >
                              {{ $t(`pricingParametersOptions.${camelize(String(param.name))}.${camelize(String(option))}`) }}
                            </option>
                          </select>
                          <label :for="`pricing_parameters[${param.id}]`">{{ $t(`pricingParametersOptions.${camelize(param.name)}Label`) }}</label>
                        </div>
                      </template>
                    </div>
                  </div>

                  <div class="form-section">
                    <loading
                      :active.sync="extrasLoading"
                      :can-cancel="false"
                      :is-full-page="false"
                    ></loading>
                    <h3>{{ $t('extras') }}<br><small>{{ $t('extrasInstructions') }}</small></h3>
                    <div
                      v-if="service && service.extras && service.extras.length > 0"
                      class="extras-grid"
                    >
                      <div v-if="recurringExtras">
                        <p><strong>{{ $t('recurring') }}</strong></p>
                        <div
                          class="btn-group extras"
                          role="group"
                        >
                          <template v-for="extra in service.extras">
                            <button
                              v-if="extra.recurring && extra.id !== keyboxID"
                              :key="extra.id"
                              :class="{active: isSelectedExtras(extra.id)}"
                              class="btn"
                              type="button"
                              @click="setExtra($event, extra.id)"
                            >
                              <span class="image">
                                <span class="svgs">
                                  <svg
                                    v-if="!isSelectedExtras(extra.id)"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-square"><rect
                                      x="3"
                                      y="3"
                                      width="18"
                                      height="18"
                                      rx="2"
                                      ry="2"
                                    />
                                  </svg>
                                  <svg
                                    v-else
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-check-square"
                                  >
                                    <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"/>
                                    <polyline points="9 11 12 14 22 4"/>
                                  </svg>
                                </span>
                                <!-- <img
                                  :src="`${baseUrl}assets/images/extras-${extraImage(extra.name)}.png`"
                                  alt="">
                                <img
                                  :src="`${baseUrl}assets/images/extras-${extraImage(extra.name)}_white.png`"
                                  alt=""> -->
                                <!-- {{ extraImage(extra.name) }} -->

                                <svg
                                  v-if="extraImage(extra.name) === 'soussolBasement'"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 300 300"
                                >
                                  <g>
                                    <g>
                                      <rect
                                        class="cls-1"
                                        x="179"
                                        y="231"
                                        width="12"
                                        height="25"/>
                                      <path
                                        class="cls-1"
                                        d="M300,12V0H241a6,6,0,0,0-3.42,1.07l-235,163,6.84,9.86L19,167.29V232H6a6,6,0,0,0-6,6v56a6,6,0,0,0,6,6H294a6,6,0,0,0,6-6V94a6,6,0,0,0-6-6H243V12ZM172,124a6,6,0,0,0-6,6v33H138V85h-.37L179,56.31V124Zm-56,39a6,6,0,0,0-6,6v26H84V122.2l42-29.13V163Zm-85-4,41-28.44V195H61a6,6,0,0,0-6,6v31H31ZM155,288V210h94v78Zm133,0H261V204a6,6,0,0,0-6-6H149a6,6,0,0,0-6,6v84H12V244H61a6,6,0,0,0,6-6V207h49a6,6,0,0,0,6-6V175h50a6,6,0,0,0,6-6V136h42a6,6,0,0,0,6-6V100h62ZM220,88a6,6,0,0,0-6,6v30H191V48l40-27.74V88Z"/>
                                    </g>
                                  </g>
                                </svg>

                                <svg
                                  v-if="extraImage(extra.name) === 'salleDeauSoussolBasementHalfBathroom'"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 261 299"
                                >
                                  <g>
                                    <g>
                                      <path
                                        class="cls-1"
                                        d="M230.61,35H224V19.38A19.4,19.4,0,0,0,204.62,0H19.38A19.4,19.4,0,0,0,0,19.38v81.24A19.4,19.4,0,0,0,19.38,120H34v23.44a23.49,23.49,0,0,0,.38,46.19A77.69,77.69,0,0,0,67,245.72V287H35v12H189V287H157V245.72a77.69,77.69,0,0,0,32.62-56.09,23.49,23.49,0,0,0,.38-46.19V120h14.62A19.4,19.4,0,0,0,224,100.62V47h6.61A18.41,18.41,0,0,1,249,65.39V80h12V65.39A30.42,30.42,0,0,0,230.61,35ZM178,143H46V113a66,66,0,0,1,132,0Zm-30.25,94.49a6,6,0,0,0-2.75,5V287H79V242.53a6,6,0,0,0-2.75-5A65.8,65.8,0,0,1,46.51,190h131A65.8,65.8,0,0,1,147.75,237.49Zm49.25-71a11.51,11.51,0,0,1-8.84,11.18A6,6,0,0,0,184,176H40a6,6,0,0,0-4.16,1.68A11.5,11.5,0,0,1,38.5,155h147A11.51,11.51,0,0,1,197,166.5Zm15-65.88a7.39,7.39,0,0,1-7.38,7.38h-14.8a78,78,0,0,0-155.64,0H19.38A7.39,7.39,0,0,1,12,100.62V19.38A7.39,7.39,0,0,1,19.38,12H204.62A7.39,7.39,0,0,1,212,19.38Z"/>
                                    </g>
                                  </g>
                                </svg>

                                <svg
                                  v-if="extraImage(extra.name) === 'salleDeBainsSoussolBasementBathroom'"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 276 299"
                                >
                                  <g>
                                    <g>
                                      <path
                                        class="cls-1"
                                        d="M270,161H250V38.5a38.5,38.5,0,0,0-77-1A35.05,35.05,0,0,0,144,72v6h70V72a35,35,0,0,0-29-34.47,26.5,26.5,0,0,1,53,1V161H108.36c-3.17-14.79-17.82-26-35.36-26s-32.19,11.21-35.36,26H6a6,6,0,0,0-6,6v24a6,6,0,0,0,6,6H23v24.5a61.59,61.59,0,0,0,38,56.82V299H73V281.91A62.27,62.27,0,0,0,84.5,283h110a61.65,61.65,0,0,0,10.5-.92V299h12V278.72a61.59,61.59,0,0,0,39-57.22V197h14a6,6,0,0,0,6-6V167A6,6,0,0,0,270,161ZM201.21,66H156.79a23,23,0,0,1,44.42,0ZM73,147c10.73,0,19.83,5.89,22.9,14H50.1C53.17,152.89,62.27,147,73,147Zm171,74.5A49.56,49.56,0,0,1,194.5,271H84.5A49.56,49.56,0,0,1,35,221.5V197H244ZM264,185H12V173H264Z"/>
                                      <rect
                                        class="cls-1"
                                        x="144"
                                        y="89.86"
                                        width="12"
                                        height="11.86"/>
                                      <rect
                                        class="cls-1"
                                        x="144"
                                        y="113.57"
                                        width="12"
                                        height="11.86"/>
                                      <rect
                                        class="cls-1"
                                        x="144"
                                        y="137.29"
                                        width="12"
                                        height="11.86"/>
                                      <rect
                                        class="cls-1"
                                        x="173"
                                        y="113.57"
                                        width="12"
                                        height="11.86"/>
                                      <rect
                                        class="cls-1"
                                        x="173"
                                        y="89.86"
                                        width="12"
                                        height="11.86"/>
                                      <rect
                                        class="cls-1"
                                        x="173"
                                        y="137.29"
                                        width="12"
                                        height="11.86"/>
                                      <rect
                                        class="cls-1"
                                        x="202"
                                        y="89.86"
                                        width="12"
                                        height="11.86"/>
                                      <rect
                                        class="cls-1"
                                        x="202"
                                        y="113.57"
                                        width="12"
                                        height="11.86"/>
                                      <rect
                                        class="cls-1"
                                        x="202"
                                        y="137.29"
                                        width="12"
                                        height="11.86"/>
                                    </g>
                                  </g>
                                </svg>

                                <svg
                                  v-if="extraImage(extra.name) === 'cuisineSoussolBasementKitchen'"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 264.34 239.04"
                                >
                                  <g>
                                    <g>
                                      <path
                                        class="cls-1"
                                        d="M172.4,105.8l35-4.58a4,4,0,0,0,2.91-1.92l54-54-5.65-5.65L206.78,91.51l-7.44-7.45L251,32.45l-5.66-5.66L193.68,78.41l-7.74-7.74,51.61-51.62-5.65-5.66L180.28,65l-7.44-7.44L224.75,5.66,219.09,0,165,54.08A4,4,0,0,0,163.13,57l-4.49,34.31L131.1,118.85l-.17-.15,1.35-22.45a26.72,26.72,0,0,0-7.77-20.49L89.11,40.37a128.28,128.28,0,0,0-81-37.22h0l.5.5L7.07,2.19,0,9.26,120.33,129.63,25.16,224.91,39.31,239l95.16-95.27L229.71,239l14.14-14.14-98.18-92.34Z"/>
                                    </g>
                                  </g>
                                </svg>
                              </span>
                              <span class="name">
                                {{ $t(`extrasOptions.${camelize(extra.name)}`) }}
                                <!-- <strong v-if="extra.price > 0">(+{{ extra.price }}$)</strong> -->
                              </span>
                            </button>
                          </template>
                        </div>
                      </div>
                      <div v-if="oneTimeExtras">
                        <p><strong>{{ $t('oneTime') }}</strong></p>
                        <div
                          class="btn-group extras"
                          role="group"
                        >
                          <template v-for="extra in service.extras">
                            <button
                              v-if="!extra.recurring && extra.id !== keyboxID"
                              :key="extra.id"
                              :class="{active: isSelectedExtras(extra.id)}"
                              class="btn"
                              type="button"
                              @click="setExtra($event, extra.id)"
                            >
                              <span class="image">
                                <span class="svgs">
                                  <svg
                                    v-if="!isSelectedExtras(extra.id)"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-square"><rect
                                      x="3"
                                      y="3"
                                      width="18"
                                      height="18"
                                      rx="2"
                                      ry="2"
                                    />
                                  </svg>
                                  <svg
                                    v-else
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-check-square"
                                  >
                                    <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"/>
                                    <polyline points="9 11 12 14 22 4"/>
                                  </svg>
                                </span>
                                <!-- <img
                                  :src="`${baseUrl}assets/images/extras-${extraImage(extra.name)}.png`"
                                  alt="">
                                <img
                                  :src="`${baseUrl}assets/images/extras-${extraImage(extra.name)}_white.png`"
                                  alt=""> -->
                                <!-- {{ extraImage(extra.name) }} -->

                                <svg
                                  v-if="extraImage(extra.name) === 'intrieurDuFrigoInsideFridge'"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 189 302"
                                >
                                  <g>
                                    <g>
                                      <path
                                        class="cls-1"
                                        d="M175.71,0H13.29A13.3,13.3,0,0,0,0,13.29V288.71A13.3,13.3,0,0,0,13.29,302H175.71A13.3,13.3,0,0,0,189,288.71V13.29A13.3,13.3,0,0,0,175.71,0ZM13.29,12H175.71A1.29,1.29,0,0,1,177,13.29V105H12V13.29A1.29,1.29,0,0,1,13.29,12ZM175.71,290H13.29A1.29,1.29,0,0,1,12,288.71V117H177V288.71A1.29,1.29,0,0,1,175.71,290Z"/>
                                      <rect
                                        class="cls-1"
                                        x="37"
                                        y="43"
                                        width="12"
                                        height="32"/>
                                      <rect
                                        class="cls-1"
                                        x="37"
                                        y="151"
                                        width="12"
                                        height="32"/>
                                    </g>
                                  </g>
                                </svg>

                                <svg
                                  v-if="extraImage(extra.name) === 'intrieurDesArmoiresDeCuisineInsideKitchenCabinets'"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 275 260.5"
                                >
                                  <g>
                                    <g>
                                      <path
                                        class="cls-1"
                                        d="M269,0H6A6,6,0,0,0,0,6V231a6,6,0,0,0,6,6h8.5v17.5a6,6,0,0,0,6,6h235a6,6,0,0,0,6-6V237H269a6,6,0,0,0,6-6V6A6,6,0,0,0,269,0ZM12,12H132.5V224.5H20.5a6,6,0,0,0-2.39.5H12ZM249.5,248.5H26.5V237h223ZM263,225h-5.11a6,6,0,0,0-2.39-.5h-111V12H263Z"/>
                                      <circle
                                        class="cls-1"
                                        cx="172.5"
                                        cy="117.5"
                                        r="10"/>
                                      <circle
                                        class="cls-1"
                                        cx="104.5"
                                        cy="117.5"
                                        r="10"/>
                                    </g>
                                  </g>
                                </svg>

                                <svg
                                  v-if="extraImage(extra.name) === 'fentresIntrieuresInteriorWindows'"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 236 337"
                                >
                                  <g>
                                    <g>
                                      <path
                                        class="cls-1"
                                        d="M220,278h-2.38V99.5a99.5,99.5,0,0,0-199,0V278H16a16,16,0,0,0,0,32h3.06A33,33,0,0,0,51.5,337h133a33,33,0,0,0,32.44-27H220a16,16,0,0,0,0-32ZM30.62,99.5a87.5,87.5,0,0,1,175,0V278H195V98A77,77,0,0,0,41,98V278H30.62ZM124,278V190h63v88Zm63-100H124V103h63ZM133.25,91l37.46-37.46A68.7,68.7,0,0,1,186.65,91ZM124,83.28v-54a68.64,68.64,0,0,1,38.21,15.79ZM112,84,73.39,45.42A68.72,68.72,0,0,1,112,29.28ZM64.94,53.94,102,91H49.35A68.74,68.74,0,0,1,64.94,53.94ZM49,103h63v75H49Zm0,87h63v88H49ZM184.5,325H51.5a21,21,0,0,1-20.13-15H204.63A21,21,0,0,1,184.5,325ZM220,298H16a4,4,0,0,1,0-8H220a4,4,0,0,1,0,8Z"/>
                                    </g>
                                  </g>
                                </svg>
                              </span>
                              <span class="name">
                                {{ $t(`extrasOptions.${camelize(extra.name)}`) }}
                                <!-- <strong v-if="extra.price > 0">(+{{ extra.price }}$)</strong> -->
                              </span>
                            </button>
                          </template>
                        </div>
                      </div>
                    </div>

                    <!-- <a
                      href="#"
                      @click="toggleComment"
                    >{{ $t('commentsSection') }}</a>
                    v-if="showComment" -->
                    <div
                      class="comment-section">
                      <h3>
                        <small>{{ $t('customerCommentsInstructions') }}</small>
                      </h3>
                      <div class="field form-group">
                        <textarea
                          v-model="form.customer_notes"
                          class="form-control"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <template v-if="estimate && estimate.duration && estimate.duration > 480">
                    <div class="form-section service-too-long">
                      <p>{{ $t('serviceTooLong') }}</p>
                      <p>
                        <a :href="`tel:${telephoneNumber}`">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/>
                          </svg>
                          {{ telephoneNumber }}
                        </a>
                      </p>
                    </div>
                  </template>

                  <template v-else>
                    <div class="form-section">
                      <frequencies
                        :loading="frequenciesLoading"
                        :frequencies="frequencies"
                        :selectedFrequency="form.frequency_id"
                        :setFrequency="setFrequency"
                      ></frequencies>
                      <p
                        v-if="showErrors.indexOf('frequency') > -1"
                        class="error-section frequency"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-alert-triangle"
                        >
                          <path
                            d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
                          <line
                            x1="12"
                            y1="9"
                            x2="12"
                            y2="13" />
                          <line
                            x1="12"
                            y1="17"
                            x2="12"
                            y2="17" />
                        </svg>
                        {{ $t('frequencyError') }}
                      </p>
                    </div>

                    <div class="form-section">
                      <loading
                        :active.sync="customFieldsLoading"
                        :can-cancel="false"
                        :is-full-page="false"
                      ></loading>
                      <h3>{{ $t('accessTitle') }}</h3>
                      <p
                        v-if="showErrors.indexOf('customField') > -1"
                        class="error-section access"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-alert-triangle"
                        >
                          <path
                            d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
                          <line
                            x1="12"
                            y1="9"
                            x2="12"
                            y2="13" />
                          <line
                            x1="12"
                            y1="17"
                            x2="12"
                            y2="17" />
                        </svg>
                        {{ $t('accessError') }}
                      </p>
                      <template
                        v-for="customField in customFields"
                      >
                        <template v-if="customField.control_type === 'drop_down' || customField.control_type === 'multi_line'">
                          <div
                            v-if="customField.control_type === 'drop_down'"
                            :key="customField.id"
                          >
                            <div
                              class="btn-group customfields"
                              role="group"
                            >
                              <button
                                v-for="option in customField.options"
                                :key="option.key"
                                :class="{active: isSelectedCustomField(customField.id, option.id)}"
                                class="btn"
                                type="button"
                                @click="setCustomFieldButton(customField.id, option.id)"
                              >
                                <span class="image">
                                  <svg
                                    v-if="camelize(option.label) === 'jeSeraiPrsentIWillBeHome'"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-home">
                                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/>
                                    <polyline points="9 22 9 12 15 12 15 22"/>
                                  </svg>
                                  <svg
                                    v-if="camelize(option.label) === 'jeCacheraiUneClOuFourniraiUnCodeIWillHideAKeyOrGiveACode'"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-key"
                                  >
                                    <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"/>
                                  </svg>
                                  <svg
                                    v-if="camelize(option.label) === 'jutiliseraiUneBoteClIWillUseAKeybox'"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-lock"
                                  >
                                    <rect
                                      x="3"
                                      y="11"
                                      width="18"
                                      height="11"
                                      rx="2"
                                      ry="2"
                                    />
                                    <path d="M7 11V7a5 5 0 0 1 10 0v4"/>
                                  </svg>
                                  <svg
                                    v-if="camelize(option.label) === 'autreOtherVeuillezPrciserPleaseSpecify'"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-alert-circle">
                                    <circle
                                      cx="12"
                                      cy="12"
                                      r="10"/>
                                    <line
                                      x1="12"
                                      y1="8"
                                      x2="12"
                                      y2="12"/>
                                    <line
                                      x1="12"
                                      y1="16"
                                      x2="12"
                                      y2="16"/>
                                  </svg>
                                </span>
                                <span class="name">
                                  {{ $t(`customFields.${camelize(option.label)}`) }}
                                  <strong v-if="option.id === 30">(+25$)</strong>
                                </span>
                              </button>
                            </div>
                            <transition name="fade">
                              <div
                                v-if="isSelectedExtras(keyboxID)"
                                class="purchase-keybox"
                              >
                                <img
                                  :src="`${baseUrl}assets/images/keybox.jpg`"
                                  alt="Keybox"
                                >
                                <div class="pb-content">
                                  <h4>{{ $t('keyboxTitle') }} <small>(+25$)</small></h4>
                                  <p>{{ $t('keyboxText') }}</p>
                                </div>
                              </div>
                            </transition>
                          </div>

                          <!-- <select
                            v-if="customField.control_type === 'drop_down'"
                            :value="selectedCustomFields[customField.id]"
                            class="form-control"
                            @change="setCustomField($event, customField.id)"
                          >
                            <option
                              :value="null"
                              disabled
                            >{{ $t('pleaseSelectOption') }}</option>
                            <option
                              v-for="option in customField.options"
                              :key="option.key"
                              :value="option.id"
                            >
                              {{ $t(`customFields.${camelize(option.label)}`) }}
                            </option>
                          </select> -->
                          <div
                            v-else-if="customField.control_type === 'multi_line' && showAccessMore"
                            :key="customField.id"
                            class="field form-group"
                          >
                            <textarea
                              :value="selectedCustomFields[customField.id]"
                              :placeholder="$t('keyLocationAndOther')"
                              class="form-control"
                              rows="2"
                              @change="setCustomField($event, customField.id)"
                            ></textarea>
                          </div>
                        </template>
                      </template>

                      <div
                        class="parking-form"
                      >
                        <p><strong>{{ $t('parkingInfo') }}</strong></p>
                        <div
                          class="field form-group"
                        >
                          <select
                            :value="selectedCustomFields[266] || null"
                            class="form-control"
                            @change="setCustomField($event, 266)"
                          >
                            <option
                              :value="null"
                              disabled
                            >{{ $t('pleaseSelectOption') }}</option>
                            <option
                              :value="$t('iHaveAParking')"
                            >
                              {{ $t('iHaveAParking') }}
                            </option>
                            <option
                              :value="$t('iDontHaveAParking')"
                            >
                              {{ $t('iDontHaveAParking') }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="form-section">
                      <h3>{{ $t('calendarTitle') }}<br><small>{{ $t('calendarInstruction') }}</small></h3>
                      <p
                        v-if="showErrors.indexOf('spot') > -1"
                        class="error-section spot"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-alert-triangle"
                        >
                          <path
                            d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
                          <line
                            x1="12"
                            y1="9"
                            x2="12"
                            y2="13" />
                          <line
                            x1="12"
                            y1="17"
                            x2="12"
                            y2="17" />
                        </svg>
                        {{ $t('spotError') }}
                      </p>
                      <div class="calendar-wrap">
                        <loading
                          :active.sync="calendarLoading"
                          :can-cancel="false"
                          :is-full-page="false"
                        ></loading>
                        <div class="field form-group calendar">
                          <v-date-picker
                            v-model="selectedDate"
                            :disabled-dates="disabledDates2"
                            :popoverExpanded="true"
                            :popoverKeepVisibleOnInput="true"
                            :popoverContentOffset="0"
                            :theme-styles="themeStyles"
                            :attributes="calendarDatesAttribute"
                            :popoverVisibility="!$v.form.zip.isPostalCode ? 'none' : 'focus'"
                            tint-color="#00b6bd"
                            is-required
                            mode="single"
                            @input="onDateSelect"
                            @dayclick="checkIfNextMonth($event)"
                            @popover-will-appear="scrollToCalendar"
                          >
                            <span
                              slot="header"
                              slot-scope="{ monthLabel, yearLabel, month, year, movePrevMonth, moveNextMonth }"
                              class="calendar-header"
                            >
                              <button
                                type="button"
                                @click="updateCalendar($event, month, year, 'prev', moveNextMonth, movePrevMonth)"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-chevrons-left"
                                >
                                  <polyline points="11 17 6 12 11 7"/>
                                  <polyline points="18 17 13 12 18 7"/>
                                </svg>
                              </button>

                              <span>{{ monthLabel }} {{ yearLabel }}</span>

                              <button
                                type="button"
                                @click="updateCalendar($event, month, year, 'next', moveNextMonth, movePrevMonth)"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-chevrons-right"
                                >
                                  <polyline points="13 17 18 12 13 7"/>
                                  <polyline points="6 17 11 12 6 7"/>
                                </svg>
                              </button>
                            </span>
                            <input
                              slot-scope="{ inputValue, updateValue }"
                              :value="inputValue"
                              :placeholder="$t('selectDate')"
                              :disabled="!$v.form.zip.isPostalCode"
                              type="text"
                              class="form-control"
                              readonly
                              @input="updateValue($event.target.value, { formatInput: false, hidePopover: false })"
                              @change="updateValue($event.target.value, { formatInput: true, hidePopover: false })"
                              @keyup.esc="updateValue(myDate, { formatInput: true, hidePopover: true })"
                            >
                          </v-date-picker>
                        </div>

                        <div
                          v-if="date"
                          class="spot-selector"
                        >
                          <div class="spot-listing">
                            <button
                              v-for="(spot, i) in spots"
                              :key="spot.hours"
                              :disabled="!spot.free && 'disabled'"
                              :class="{selected: isSelectedSpot(spot)}"
                              type="button"
                              @click="selectSpot(i)"
                            >
                              {{ spot.hours }}:{{ spot.minutes || '00' }}
                            </button>
                          </div>
                        </div>
                        <div
                          v-else
                          class="spot-selector"
                        >
                          <div class="spot-listing">
                            <button
                              :disabled="true"
                              type="button"
                            >
                              8:00
                            </button>
                            <button
                              :disabled="true"
                              type="button"
                            >
                              12:00
                            </button>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="form-section">
                      <h3>{{ $t('contactInformation') }}</h3>
                      <p
                        v-if="showErrors.indexOf('address') > -1"
                        class="error-section address"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-alert-triangle"
                        >
                          <path
                            d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
                          <line
                            x1="12"
                            y1="9"
                            x2="12"
                            y2="13" />
                          <line
                            x1="12"
                            y1="17"
                            x2="12"
                            y2="17" />
                        </svg>
                        {{ $t('contactError') }}
                      </p>
                      <div class="contact-fields">
                        <div
                          class="field form-group"
                        >
                          <input
                            id="firstName"
                            v-model.trim="$v.form.user.first_name.$model"
                            name="firstName"
                            class="form-control"
                            type="text"
                            @blur="$v.form.user.first_name.$touch"
                          >
                          <label for="firstName">{{ $t('firstName') }}</label>
                          <div
                            v-if="$v.form.user.first_name.$dirty && $v.form.user.first_name.$invalid"
                            class="error">{{ $t('fieldIsRequired') }}</div>
                        </div>
                        <div class="field form-group">
                          <input
                            id="lastName"
                            v-model.trim="$v.form.user.last_name.$model"
                            name="lastName"
                            class="form-control"
                            type="text"
                            @blur="$v.form.user.last_name.$touch"
                          >
                          <label for="lastName">{{ $t('lastName') }}</label>
                          <div
                            v-if="$v.form.user.last_name.$dirty && $v.form.user.last_name.$invalid"
                            class="error">{{ $t('fieldIsRequired') }}</div>
                        </div>
                        <div class="field form-group">
                          <input
                            id="email"
                            v-model.trim="$v.form.user.email.$model"
                            name="email"
                            class="form-control"
                            type="email"
                            @blur="$v.form.user.email.$touch"
                          >
                          <label for="email">{{ $t('email') }}</label>
                          <div
                            v-if="$v.form.user.email.$dirty && $v.form.user.email.$invalid && !$v.form.user.email.required"
                            class="error">{{ $t('fieldIsRequired') }}</div>
                          <div
                            v-else-if="$v.form.user.email.$dirty && $v.form.user.email.$invalid && !$v.form.user.email.email"
                            class="error">{{ $t('fieldMustBeValidEmail') }}</div>
                        </div>

                        <div class="field form-group">
                          <input
                            id="phone"
                            v-model.trim="$v.form.phone.$model"
                            name="phone"
                            class="form-control"
                            type="tel"
                            @blur="$v.form.phone.$touch">
                          <label for="phone">{{ $t('phone') }}</label>
                          <div
                            v-if="$v.form.phone.$dirty && $v.form.phone.$invalid && !$v.form.phone.required"
                            class="error">{{ $t('fieldIsRequired') }}</div>
                          <div
                            v-else-if="$v.form.phone.$dirty && $v.form.phone.$invalid && !$v.form.phone.isPhone"
                            class="error">{{ $t('fieldMustBePhoneNumber') }}</div>
                        </div>
                        <div class="field form-group">
                          <input
                            id="address"
                            v-model.trim="$v.form.address.$model"
                            name="address"
                            class="form-control"
                            type="text"
                            @blur="$v.form.address.$touch"
                          >
                          <label for="address">{{ $t('address') }}</label>
                          <div
                            v-if="$v.form.address.$dirty && $v.form.address.$invalid"
                            class="error">{{ $t('fieldIsRequired') }}</div>
                        </div>
                        <div class="field form-group">
                          <input
                            id="apt"
                            v-model="form.apt"
                            name="address2"
                            class="form-control"
                            type="text"
                          >
                          <label for="apt">{{ $t('apt') }}</label>
                        </div>
                        <div class="field form-group">
                          <input
                            id="city"
                            v-model.trim="$v.form.city.$model"
                            name="city"
                            class="form-control"
                            type="text"
                            @blur="$v.form.city.$touch"
                          >
                          <label for="city">{{ $t('city') }}</label>
                          <div
                            v-if="$v.form.city.$dirty && $v.form.city.$invalid"
                            class="error">{{ $t('fieldIsRequired') }}</div>
                        </div>
                        <div class="field form-group">
                          <select
                            id="province"
                            v-model.trim="$v.form.state.$model"
                            :disabled="forceProvince"
                            name="province"
                            class="form-control"
                            @blur="$v.form.state.$touch"
                          >
                            <option
                              value=""
                              disabled>{{ $t('selectProvince') }}</option>
                            <option
                              v-for="province in provincesList"
                              :key="province.code"
                              :value="province.code">{{ province.name }}</option>
                          </select>
                          <label for="province">{{ $t('province') }}</label>
                          <div
                            v-if="$v.form.state.$dirty && $v.form.state.$invalid"
                            class="error">{{ $t('fieldIsRequired') }}</div>
                        </div>
                        <div
                          class="field form-group"
                        >
                          <input
                            :value="form.zip"
                            class="form-control"
                            type="text"
                            disabled="disabled"
                          >
                          <label for="postalCode">{{ $t('postalCode') }}</label>
                          <!-- <div
                            v-if="$v.form.zip.$dirty && $v.form.zip.$invalid && !$v.form.zip.required"
                            class="error">{{ $t('fieldIsRequired') }}</div>
                          <div
                            v-else-if="$v.form.zip.$dirty && $v.form.zip.$invalid && !$v.form.zip.isPostalCode"
                            class="error">{{ $t('fieldMustBePostalCode') }}
                          </div> -->
                        </div>
                      </div>

                    </div>

                    <div class="form-section">
                      <!-- <payment
                        ref="payment"
                        :postalCode="form.zip"
                        :token="form.stripe_token"
                        class="payment"
                        @paymentLoading="loadPayment($event)"
                        @clearPayment="clearPayment"
                        @pay="setPayment"
                      ></payment> -->

                      <h3>
                        {{ $t('cash.title') }}
                        <small style="display: block; margin-top: 10px; line-height: 20px;">{{ $t('cash.description') }}</small>
                      </h3>

                      <loading
                        :active.sync="discountCodeLoading"
                        :can-cancel="false"
                        :is-full-page="false"
                      ></loading>
                      <h4 v-if="form.payment_method === 'cash'">{{ $t('discountCodeOrGiftCard') }}</h4>
                      <div v-if="estimate && estimate.discount_message">
                        <p
                          class="error-section"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-alert-triangle"
                          >
                            <path
                              d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
                            <line
                              x1="12"
                              y1="9"
                              x2="12"
                              y2="13" />
                            <line
                              x1="12"
                              y1="17"
                              x2="12"
                              y2="17" />
                          </svg>
                          {{ estimate.discount_message }}
                        </p>
                      </div>
                      <div v-if="discountCodeAmount > 0">
                        <div class="field form-group promo-field">
                          <label v-if="form.payment_method === 'stripe'" class="force-label">{{ $t('discountCodeOrGiftCard') }}</label>
                          <div class="form-control">
                            <span class="badge">{{ form.discount_code }} (-{{ discountCodeAmount | formatAmount }})</span>
                          </div>
                          <button
                            class="btn"
                            type="button"
                            @click="deleteDiscountCode"
                          >{{ $t('remove') }}</button>
                        </div>
                      </div>
                      <div v-else>
                        <div class="field form-group promo-field">
                          <input
                            id="discountCode"
                            v-model="form.discount_code"
                            class="form-control"
                            type="text"
                            @keypress.enter="discountKeyPress">
                          <label v-if="form.payment_method === 'stripe'" for="discountCode">{{ $t('discountCodeOrGiftCard') }}</label>
                          <!-- :disabled="!form.discount_code" -->
                          <button
                            class="btn"
                            type="button"
                            @click="estimatePrice"
                          >{{ $t('apply') }}</button>
                        </div>
                      </div>

                      <confirmation
                        class="confirmation"
                        @toggleConfirm="toggleConfirm"
                      >
                        <p
                          v-if="showErrors.indexOf('confirm') > -1"
                          class="error-section confirm"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-alert-triangle"
                          >
                            <path
                              d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
                            <line
                              x1="12"
                              y1="9"
                              x2="12"
                              y2="13" />
                            <line
                              x1="12"
                              y1="17"
                              x2="12"
                              y2="17" />
                          </svg>
                          {{ $t('confirmError') }}
                        </p>
                      </confirmation>

                      <estimate
                        :loading="estimateLoading"
                        :estimate="estimate"
                        :form="form"
                        :frequencies="frequencies"
                        :service="service"
                        class="hidden-lg"
                      ></estimate>

                      <p
                        v-if="lauchErrors && lauchErrors.length > 0"
                        class="error-section"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-alert-triangle"
                        >
                          <path
                            d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
                          <line
                            x1="12"
                            y1="9"
                            x2="12"
                            y2="13" />
                          <line
                            x1="12"
                            y1="17"
                            x2="12"
                            y2="17" />
                        </svg>
                        <span
                          v-for="(error, index) in lauchErrors"
                          :key="index"
                        >
                          {{ error }}
                        </span>
                      </p>

                      <book-now
                        :canBook="canBook"
                        @book="book"
                      ></book-now>
                    </div>
                  </template>
                </div>
              </div>
              <div class="summary-wrapper">
                <div>
                  <div class="location">
                    <template v-if="form.zip">
                      <span><strong v-if="locationName">{{ locationName }}</strong>&nbsp;<small>({{ formattedZip }})</small></span>
                      <button
                        class="btn btn-link"
                        type="button"
                        @click="changeLocation"
                      >
                        Changer
                      </button>
                    </template>
                    <span v-else>
                      Emplacement
                    </span>
                  </div>
                  <div class="summary">
                    <estimate
                      :loading="estimateLoading"
                      :estimate="estimate"
                      :form="form"
                      :frequencies="frequencies"
                      :service="service"
                    ></estimate>
                  </div>

                  <div class="questions">
                    <h5><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-help-circle"
                    >
                      <circle
                        cx="12"
                        cy="12"
                        r="10"/>
                      <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/>
                      <line
                        x1="12"
                        y1="17"
                        x2="12"
                        y2="17"
                        stroke-width="2"
                      />
                    </svg>Questions</h5>
                    <p><a
                      href="#q1"
                      @click="toggleQuestionsModal"
                    >{{ $t('dimensionsTooltipTitle') }}</a></p>
                    <p><a
                      href="#q2"
                      @click="toggleQuestionsModal"
                    >{{ $t('frequencyTooltipTitle') }}</a></p>
                    <p><a
                      href="#q3"
                      @click="toggleQuestionsModal"
                    >{{ $t('calendarTooltipTitle1') }}</a></p>
                    <p><a
                      href="#q4"
                      @click="toggleQuestionsModal"
                    >{{ $t('calendarTooltipTitle2') }}</a></p>
                    <p><a
                      href="#q5"
                      @click="toggleQuestionsModal"
                    >{{ $t('paymentTooltipTitle1') }}</a></p>
                    <p><a
                      href="#q6"
                      @click="toggleQuestionsModal"
                    >{{ $t('paymentTooltipTitle2') }}</a></p>
                  </div>

                  <div
                    v-show="showQuestionsModal"
                    class="modal questions-modal"
                    @click="toggleQuestionsModal($event, true)"
                  >
                    <div class="modal-content">
                      <div class="modal-header">
                        <h2>
                          Questions ?
                          <a
                            href="#"
                            @click="toggleQuestionsModal($event, false)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-x"
                            >
                              <line
                                x1="18"
                                y1="6"
                                x2="6"
                                y2="18"/>
                              <line
                                x1="6"
                                y1="6"
                                x2="18"
                                y2="18"/>
                            </svg>
                          </a>
                        </h2>
                      </div>
                      <div class="modal-body">
                        <div
                          id="q1"
                          :class="{ active: question === 'q1' }"
                        >
                          <p><strong>{{ $t('dimensionsTooltipTitle') }}</strong></p>
                          <p v-html="$t('dimensionsTooltip1')"></p>
                          <p v-html="$t('dimensionsTooltip2')"></p>
                        </div>
                        <div
                          id="q2"
                          :class="{ active: question === 'q2' }"
                        >
                          <p><strong>{{ $t('frequencyTooltipTitle') }}</strong></p>
                          <p v-html="$t('frequencyTooltip1')"></p>
                        </div>
                        <div
                          id="q3"
                          :class="{ active: question === 'q3' }"
                        >
                          <p><strong>{{ $t('calendarTooltipTitle1') }}</strong></p>
                          <p v-html="$t('calendarTooltip1')"></p>
                        </div>
                        <div
                          id="q4"
                          :class="{ active: question === 'q4' }"
                        >
                          <p><strong>{{ $t('calendarTooltipTitle2') }}</strong></p>
                          <p v-html="$t('calendarTooltip2')"></p>
                        </div>
                        <div
                          id="q5"
                          :class="{ active: question === 'q5' }"
                        >
                          <p><strong>{{ $t('paymentTooltipTitle1') }}</strong></p>
                          <p v-html="$t('paymentTooltip1')"></p>
                        </div>
                        <div
                          id="q6"
                          :class="{ active: question === 'q6' }"
                        >
                          <p><strong>{{ $t('paymentTooltipTitle2') }}</strong></p>
                          <p v-html="$t('paymentTooltip2')"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-show="showPostalModal"
              class="modal postal-modal"
            >
              <div class="modal-content">
                <div class="modal-header">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="47.832"
                    height="63.776"
                    viewBox="0 0 47.832 63.776"
                  >
                    <path
                      d="M23.916,11.958A11.958,11.958,0,1,0,35.874,23.916,11.971,11.971,0,0,0,23.916,11.958Zm0,19.93a7.972,7.972,0,1,1,7.972-7.972A7.981,7.981,0,0,1,23.916,31.888ZM23.916,0A23.916,23.916,0,0,0,0,23.916c0,9.643,3.359,12.336,21.458,38.573a2.991,2.991,0,0,0,4.916,0c18.1-26.238,21.458-28.931,21.458-38.573A23.916,23.916,0,0,0,23.916,0Zm0,59.034C6.565,33.942,3.986,31.95,3.986,23.916A19.93,19.93,0,0,1,38.009,9.823a19.8,19.8,0,0,1,5.837,14.093C43.846,31.949,41.269,33.94,23.916,59.034Z"/>
                  </svg>
                  <h2>{{ $t('whatIsYourPostalCode') }}</h2>
                  <a
                    v-if="form.zip"
                    @click="cancelPostalCodeChange"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-x"
                    >
                      <line
                        x1="18"
                        y1="6"
                        x2="6"
                        y2="18"
                      />
                      <line
                        x1="6"
                        y1="6"
                        x2="18"
                        y2="18"
                      />
                    </svg>
                  </a>
                </div>
                <div class="modal-body">
                  <loading
                    :active.sync="isPostalCodeLoading"
                    :can-cancel="false"
                    :is-full-page="false"
                  ></loading>

                  <p>{{ $t('enterYourPostalCode') }}</p>
                  <div
                    v-if="postalCodeError"
                    class="error-section"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-alert-triangle"
                    >
                      <path
                        d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
                      <line
                        x1="12"
                        y1="9"
                        x2="12"
                        y2="13" />
                      <line
                        x1="12"
                        y1="17"
                        x2="12"
                        y2="17" />
                    </svg>
                    {{ $t(postalCodeError) }}
                  </div>
                  <div class="horizontal-form">
                    <div class="field form-group">
                      <input
                        id="postalCode"
                        v-model.trim="$v.postalCode.$model"
                        class="form-control"
                        type="text"
                        required
                        maxlength="7"
                        placeholder="J1J 2J3"
                        @blur="blurPostal"
                        @keypress.enter="savePostalCode"
                      >
                      <div
                        v-if="$v.postalCode.$dirty && !$v.postalCode.required"
                        class="error">{{ $t('fieldIsRequired') }}</div>
                        <!-- <div
                        v-else-if="$v.postalCode.$dirty && $v.postalCode.$invalid && !$v.postalCode.isPostalCode"
                        class="error">{{ $t('fieldMustBePostalCode') }}</div> -->
                    </div>
                    <button
                      :disabled="!$v.postalCode.isPostalCode || isPostalCodeLoading"
                      class="btn"
                      type="button"
                      @click="savePostalCode"
                    >
                      Go!
                    </button>
                  </div>

                  <!-- <p>You can also book by phone: <a href="tel:1-819-303-3088">1-819-303-3088</a></p> -->
                  <p><small v-html="$t('canAlsoBookByPhone')"></small></p>
                </div>
              </div>
            </div>
          </div>

          <success
            v-if="step === 1"
            :key="1"
            @bookAgain="bookAgain"
          ></success>

        </transition>

      </div>
    </form>
  </div>
</template>

<script>
import smoothscroll from 'smoothscroll-polyfill';
import Loading from 'vue-loading-overlay';
import { required, email } from 'vuelidate/lib/validators';
import moment from 'moment';

import Frequencies from '@/components/Frequencies.vue';

import Payment from '@/components/Payment.vue';
import Confirmation from '@/components/Confirmation.vue';
import Estimate from '@/components/Estimate.vue';
import BookNow from '@/components/BookNow.vue';
import Success from '@/components/Success.vue';
import Help from '@/components/Help.vue';


import formatPostal from '@/filters/formatPostal';
import formatAmount from '@/filters/formatAmount';

import 'vue-loading-overlay/dist/vue-loading.min.css';

smoothscroll.polyfill();

const isPhone = value => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value);
const isPostalCode = value => /^[a-z]?[^dfioquwz]{1}[0-9]{1}[a-z]{1} ?[0-9]{1}[a-z]{1}[0-9]{1}$/i.test(value);

const postalCodeByProvince = {
  A: 'nl',
  B: 'ns',
  C: 'pe',
  E: 'nb',
  G: 'qc',
  H: 'qc',
  J: 'qc',
  K: 'on',
  L: 'on',
  M: 'on',
  N: 'on',
  P: 'on',
  R: 'mb',
  S: 'sk',
  T: 'ab',
  V: 'bc',
  X: ['nt', 'nu'],
  Y: 'yt',
};

export default {
  name: 'App',
  components: {
    Loading,
    Frequencies,
    Payment,
    Confirmation,
    Estimate,
    BookNow,
    Success,
    Help,
  },
  filters: {
    formatPostal,
    formatAmount,
  },
  data() {
    const hSpacing = '15px';

    return {
      isLoading: false,
      isPostalCodeLoading: false,
      showPostalModal: false,
      postalCodeError: null,
      locationID: null,
      locationName: null,
      step: 0,
      transitionSide: 'next-component',
      showErrors: [],
      lauchErrors: [],
      postalCode: '',
      forceProvince: false,
      showComment: false,
      showQuestionsModal: false,
      question: false,
      showAccessMore: false,
      date: null,
      selectedDate: null,
      calendarDatesAttribute: [],
      nextEstimateDate: null,
      provinces: [
        'ab',
        'bc',
        'mb',
        'nb',
        'nl',
        'ns',
        'on',
        'pe',
        'qc',
        'sk',
        'nt',
        'nu',
        'yt',
      ],
      form: {
        user: {
          first_name: '',
          last_name: '',
          email: '',
        },
        location_id: null,
        address: '',
        apt: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        frequency_id: null,
        service_date: '',
        arrival_window: 0,
        services: [],
        custom_fields: [],
        discount_code: null,
        customer_notes: null,
        // payment_method: 'stripe',
        payment_method: 'cash',
        stripe_token: '',
      },

      services: [],
      service: {},
      selectedServiceID: null,
      servicesLoading: false,

      pricingParameters: null,

      selectedExtras: [],
      extrasLoading: false,

      frequencies: [],
      frequenciesLoading: false,

      spots: [],
      selectedSpot: null,

      days: [],
      calendarLoading: false,

      customFieldsLoading: false,
      customFields: [],
      selectedCustomFields: {},

      discountCodeLoading: false,
      discountCode: '',
      discountCodeAmount: 0,

      complete: false,
      paymentCompleted: false,

      confirm: false,

      estimate: null,
      estimateLoading: false,

      themeStyles: {
        wrapper: {
          border: '0',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.14), 0 6px 20px 0 rgba(0, 0, 0, 0.13)',
          borderRadius: '5px',
        },
        header: {
          padding: `20px ${hSpacing}`,
        },
        weekdays: {
          fontWeight: '600',
          padding: `20px ${hSpacing} 5px ${hSpacing}`,
        },
        weeks: {
          padding: `0 ${hSpacing} ${hSpacing} ${hSpacing}`,
        },
        dayContent: {
          fontSize: '16px',
          width: '3.5rem',
          height: '3.5rem',
        },
      },
    };
  },
  validations: {
    postalCode: {
      required,
      isPostalCode,
    },
    form: {
      user: {
        first_name: {
          required,
        },
        last_name: {
          required,
        },
        email: {
          required,
          email,
        },
      },
      phone: {
        required,
        isPhone,
      },
      address: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      },
      zip: {
        required,
        isPostalCode,
      },
    },
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_BASE_URL;
    },

    // Return an array of provice's code with translated name
    provincesList() {
      return this.provinces.map(province => ({
        code: province.toUpperCase(),
        name: this.$t(`provinces.${province}`),
      }));
    },

    formattedZip() {
      if (this.form.zip) {
        const zip = this.form.zip.replace(' ', '');
        return `${zip.substring(0, 3)} ${zip.substring(3, 6)}`;
      }
      return '';
    },

    // Return an array of date to disable in the datepicker
    disabledDates() {
      const dates = [];
      this.days.forEach((day) => {
        let isDisabled = true;

        if (day.spots && day.spots.length > 0) {
          day.spots.forEach((spot) => {
            if (spot.free) {
              isDisabled = false;
            }
          });

          if (isDisabled === true) {
            const datepart = day.date.split('-');
            dates.push(new Date(datepart[0], datepart[1] - 1, datepart[2]));
          }
        }
      });
      return {
        dates,
      };
    },

    disabledDates2() {
      const dates = [];
      this.days.forEach((day) => {
        let isDisabled = true;

        if (day.spots && day.spots.length > 0) {
          day.spots.forEach((spot) => {
            if (spot.free) {
              isDisabled = false;
            }
          });

          if (isDisabled === true) {
            const datepart = day.date.split('-');
            dates.push(new Date(datepart[0], datepart[1] - 1, datepart[2]));
          }
        }
      });
      return dates;
    },

    customFieldRequired() {
      let ret = false;
      this.customFields.forEach((cc, index) => {
        if (cc.control_type !== 'checkbox' && cc.value_required === true && (!this.form.custom_fields[index] || (this.form.custom_fields[index] && !this.form.custom_fields[index].value))) {
          ret = true;
        }
      });
      return ret;
    },

    canBook() {
      let customFieldRequired = true;
      this.customFields.forEach((cc, index) => {
        if (cc.value_required === true && !this.form.custom_fields[index]) {
          customFieldRequired = false;
        }
      });
      return this.confirm && !this.$v.$invalid && this.form.service_date !== '' && this.form.frequency_id >= 0 && customFieldRequired;
    },
    keyboxID() {
      return Number(process.env.VUE_APP_LAUNCH_KEYBOX_ID);
    },

    modalCity() {
      if (typeof localStorage === 'object') {
        if (localStorage.city) {
          return localStorage.city;
        }
      }
      return null;
    },

    // isOttawaPostalCode() {
    //   const codes = [
    //     'K1Z', 'K1Y', 'K1R', 'K1D', 'K2P', 'K1S', 'K1H', 'K1V', 'K1T', 'K1A', 'K1M', 'K1E', 'K1C', 'K4A', 'K1K', 'K1W', 'K1J', 'K1B', 'K1N', 'K1G', 'K1L', 'K2K', 'K2T', 'K2V', 'K2L', 'K2M', 'K2H', 'K2R', 'K2J', 'K2G', 'K2B', 'K2E', 'K2C', 'K2A',
    //   ];
    //   if (this.postalCode) {
    //     const firstChar = this.postalCode.substring(0, 3).toUpperCase();
    //     if (codes.includes(firstChar)) {
    //       return true;
    //     }
    //   }
    //   return false;
    // },

    recurringExtras() {
      let ret = false;
      if (this.service && this.service.extras && this.service.extras.length > 0) {
        this.service.extras.forEach((ex) => {
          if (ex.recurring === true) {
            ret = true;
          }
        });
      }
      return ret;
    },
    oneTimeExtras() {
      let ret = false;
      if (this.service && this.service.extras && this.service.extras.length > 0) {
        this.service.extras.forEach((ex) => {
          if (ex.recurring === false) {
            ret = true;
          }
        });
      }
      return ret;
    },

    telephoneNumber() {
      if (this.postalCode.charAt(0).toUpperCase() === 'K') {
        return '1-613-800-8081';
      }
      return '1-819-303-3088';
    },
  },
  watch: {
    locationID(id) {
      if (id >= 0) {
        const date = new Date();

        this.loadServices(id);

        this.loadFrequencies(id);

        this.loadCustomFields();

        this.loadSpots(date, id);
      }
    },
    // isOttawaPostalCode(value) {
    //   if (value === true) {
    //     this.loadLocation();
    //   }
    // },
    selectedServiceID(id) {
      if (id) {
        if (this.services && this.services.length > 0) {
          this.service = this.services.find(s => s.id === id);

          if (typeof localStorage === 'object') {
            localStorage.setItem('service_id', id);
          }

          this.form.services = [];

          const pricingParameters = [];
          const extras = [];

          if (this.service && this.service.pricing_parameters && this.service.pricing_parameters.length > 0) {
            if (this.pricingParameters && Object.keys(this.pricingParameters).length > 0 && this.pricingParameters.constructor === Object) {
              this.service.pricing_parameters.forEach((pricingParameter) => {
                if (typeof localStorage === 'object') {
                  if (localStorage.pricing_parameters) {
                    const lsPp = JSON.parse(localStorage.pricing_parameters);
                    if (lsPp[pricingParameter.id]) {
                      this.pricingParameters[pricingParameter.id] = lsPp[pricingParameter.id];
                    }
                  }
                }

                if ((!this.pricingParameters[pricingParameter.id] && this.pricingParameters[pricingParameter.id] >= 0) || typeof this.pricingParameters[pricingParameter.id] === 'object') {
                  this.pricingParameters[pricingParameter.id] = pricingParameter.quantity_minimum;
                }

                pricingParameters.push({
                  id: pricingParameter.id,
                  quantity: this.pricingParameters[pricingParameter.id],
                });
              });
            }
          }

          if (this.service && this.service.extras && this.service.extras.length > 0) {
            if (this.selectedExtras && this.selectedExtras.length > 0) {
              this.selectedExtras.forEach((selectedExtra, index) => {
                const found = this.service.extras.find(extra => extra.id === selectedExtra.id);
                if (found) {
                  extras.push(selectedExtra);
                } else {
                  this.selectedExtras.splice(index, 1);
                }
              });
            }
          }

          this.form.services.push({
            id: this.service.id,
            pricing_parameters: pricingParameters,
            extras,
          });

          this.estimatePrice();
        }
      } else {
        this.service = {};
        this.form.services = [];
      }
    },

    selectedSpot(spot) {
      const d = this.date;
      if (spot) {
        d.setHours(spot.hours);
        d.setMinutes(spot.minutes);
        const currentDate = d.getDate();
        const currentMonth = d.getMonth() + 1;
        const currentHrs = d.getHours();
        const currentMins = d.getMinutes();
        const currentSecs = d.getSeconds();

        this.form.service_date = `${d.getFullYear()}-${currentMonth < 10 ? `0${currentMonth}` : currentMonth}-${currentDate < 10 ? `0${currentDate}` : currentDate}T${currentHrs < 10 ? `0${currentHrs}` : currentHrs}:${currentMins < 10 ? `0${currentMins}` : currentMins}:${currentSecs < 10 ? `0${currentSecs}` : currentSecs}`;
        this.estimatePrice();
      } else {
        this.form.service_date = '';
      }
    },

    nextEstimateDate() {
      this.estimatePrice();
    },

    confirm(val) {
      const foundIndex = this.form.custom_fields.findIndex(cf => cf.id === 245);
      if (foundIndex >= 0) {
        if (this.form.custom_fields[foundIndex]) {
          this.form.custom_fields[foundIndex].value = val.toString();
        }
      } else {
        this.form.custom_fields.push({
          id: 245,
          optionID: val.toString(),
        });
      }
    },
  },
  mounted() {
    if (typeof localStorage === 'object') {
      if (localStorage.postalcodeMulti) {
        this.postalCode = localStorage.postalcodeMulti;
        this.loadLocation();
      } else {
        this.showPostalModal = true;
        document.body.classList.add('noscroll');
      }
    } else {
      this.showPostalModal = true;
      document.body.classList.add('noscroll');
    }
  },
  methods: {
    camelize(val) {
      if (!val) return '';
      const text = val.replace(/[^\w\s]/gi, '');
      return text.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => (index === 0 ? letter.toLowerCase() : letter.toUpperCase())).replace(/\s+/g, '');
    },

    extraImage(val) {
      if (!val) return '';
      const text = val.replace(/[^\w\s]/gi, '').replace(/[0-9]/g, '');
      return text.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => (index === 0 ? letter.toLowerCase() : letter.toUpperCase())).replace(/\s+/g, '');
    },

    scrollToStep() {
      const container = this.$el.querySelector('.step-wrapper');
      window.scroll({
        top: window.pageYOffset + container.getBoundingClientRect().top,
        left: 0,
        behavior: 'smooth',
      });
    },

    scrollToError(sectionClass) {
      if (sectionClass) {
        setTimeout(() => {
          const elem = this.$el.querySelector(`.error-section.${sectionClass}`);
          if (elem) {
            window.scroll({
              top: window.pageYOffset + elem.getBoundingClientRect().top,
              left: 0,
              behavior: 'smooth',
            });
          }
        }, 100);
      }
    },

    goToStep(step) {
      if (step > this.step) {
        this.transitionSide = 'next-component';
      } else {
        this.transitionSide = 'previous-component';
      }
      this.step = step;
      this.scrollToStep();
    },

    blurPostal() {
      this.$v.postalCode.$touch();
    },

    savePostalCode() {
      // this.blurPostal();
      if (this.$v.postalCode.isPostalCode) {
        if (typeof localStorage === 'object') {
          localStorage.removeItem('service_id');
        }
        this.loadLocation(true);
      }
    },

    changeLocation() {
      document.body.classList.add('noscroll');
      this.showPostalModal = true;
    },

    cancelPostalCodeChange() {
      document.body.classList.remove('noscroll');
      this.showPostalModal = false;
    },

    // Request services from Launch27
    loadLocation(fromPostalCodeModal = false) {
      if (fromPostalCodeModal) {
        this.isPostalCodeLoading = true;
        this.postalCodeError = null;
      } else {
        this.isLoading = true;
      }

      this.$axios.post('/booking/location', {
        zip: this.postalCode,
      })
        .then((response) => {
          if (response && response.data) {
            if (response.data.id) {
              this.locationID = response.data.id;
              this.form.location_id = response.data.id;

              if (typeof localStorage === 'object') {
                localStorage.postalcodeMulti = this.postalCode;
              }

              const stateCode = postalCodeByProvince[this.postalCode.charAt(0).toUpperCase()];
              if (typeof stateCode === 'string') {
                this.forceProvince = true;
                this.form.state = stateCode.toUpperCase();
              } else {
                this.forceProvince = false;
              }
              if (typeof localStorage === 'object') {
                if (localStorage.frequency_id) {
                  this.setFrequency(Number(localStorage.frequency_id));
                }
              }
            }
            if (response.data.name) {
              this.locationName = response.data.name;
            }
            this.form.zip = this.postalCode;
            this.showPostalModal = false;
            document.body.classList.remove('noscroll');
          }
        })
        .catch((error) => {
          // handle error
          if (error && error.response && error.response.data && error.response.data.message) {
            this.postalCodeError = error.response.data.message;
          }
          if (typeof localStorage === 'object') {
            localStorage.postalcodeMulti = '';
          }
        })
        .then(() => {
          if (fromPostalCodeModal) {
            this.isPostalCodeLoading = false;
          } else {
            this.isLoading = false;
          }
        });
    },
    loadServices(locationID) {
      this.servicesLoading = true;
      this.extrasLoading = true;
      this.$axios.get(`/booking/services${locationID && `?location_id=${locationID}`}`)
        .then((response) => {
          if (response && response.data) {
            this.services = response.data;

            if (this.services && this.services.length > 0) {
              if (typeof localStorage === 'object' && localStorage.service_id) {
                this.selectedServiceID = Number(localStorage.service_id);
              } else {
                this.selectedServiceID = this.services[0].id;
              }
              this.services.forEach((s) => {
                if (s.pricing_parameters && s.pricing_parameters.length > 0) {
                  if (this.pricingParameters === null) {
                    this.pricingParameters = {};
                  } else {
                    s.pricing_parameters.forEach((pp) => {
                      if (!(pp.id in this.pricingParameters)) {
                        this.pricingParameters[pp.id] = {};
                      }
                    });
                  }
                }
              });
            }
          }
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .then(() => {
          this.servicesLoading = false;
          this.extrasLoading = false;
        });
    },

    // List all parameters in an array for building html
    priceParametersOptions(priceParamID) {
      const options = [];
      if (this.service && this.service.pricing_parameters && this.service.pricing_parameters.length > 0 && priceParamID) {
        const priceParam = this.service.pricing_parameters.find(pp => pp.id === priceParamID);
        if (priceParam.type === 'flat') {
          if (priceParam.quantity_minimum >= 0 && priceParam.quantity_maximum >= 0) {
            for (let i = priceParam.quantity_minimum; i <= priceParam.quantity_maximum; i += 1) {
              options.push(i);
            }
          }
        } else if (priceParam.type === 'ranged') {
          if (priceParam && priceParam.ranges && priceParam.ranges.length > 0) {
            priceParam.ranges.forEach((pp) => {
              options.push(pp.quantity);
            });
          }
        }
      }
      return options;
    },

    // Set pricing parameters
    setPricingParameters(e, paramID) {
      if (paramID) {
        const value = Number(e.target.value);
        this.pricingParameters[paramID] = value;

        let lsPp = {};
        if (typeof localStorage === 'object') {
          if (localStorage.pricing_parameters) {
            lsPp = JSON.parse(localStorage.pricing_parameters);
            lsPp[paramID] = value;
          }
        }
        lsPp[paramID] = value;
        if (typeof localStorage === 'object') {
          localStorage.setItem('pricing_parameters', JSON.stringify(lsPp));
        }

        // Possibilité de devoir retirer les paramètres s'ils ne sont pas utilisés
        const foundIndex = this.form.services[0].pricing_parameters.findIndex(pp => pp.id === paramID);

        if (foundIndex >= 0) {
          if (this.form.services[0].pricing_parameters[foundIndex]) {
            this.form.services[0].pricing_parameters[foundIndex].quantity = value;
          }
        } else {
          this.form.services[0].pricing_parameters.push({
            id: paramID,
            quantity: value,
          });
        }
        if (e) {
          this.estimatePrice();
        }
      }
    },

    // Toggle set extra
    setExtra(e, extraID) {
      const foundIndex = this.selectedExtras.findIndex(extra => extra.id === extraID);
      if (foundIndex >= 0) {
        this.selectedExtras.splice(foundIndex, 1);
      } else {
        this.selectedExtras.push({
          id: extraID,
          quantity: 1,
        });
      }

      this.form.services[0].extras = this.selectedExtras;

      if (e) {
        this.estimatePrice();
      }
    },

    // Return is extra selected
    isSelectedExtras(extraID) {
      const found = this.selectedExtras.find(extra => extra.id === extraID);
      if (found) {
        return true;
      }
      return false;
    },

    // Request frequencies from Launch27
    loadFrequencies(locationID) {
      this.frequenciesLoading = true;
      this.$axios.get(`/booking/frequencies${locationID && `?location_id=${locationID}`}`)
        .then((response) => {
          if (response && response.data) {
            this.frequencies = response.data;

            const defaultFrequency = this.frequencies.find(frequency => frequency.default);

            if (typeof localStorage === 'object' && localStorage.frequency_id) {
              this.form.frequency_id = Number(localStorage.frequency_id);
            } else if (defaultFrequency) {
              this.form.frequency_id = defaultFrequency.id;
            }
          }
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .then(() => {
          this.frequenciesLoading = false;
        });
    },

    // Set frequency
    setFrequency(frequencyID) {
      this.form.frequency_id = frequencyID;
      if (typeof localStorage === 'object') {
        if (localStorage.frequency_id && Number(localStorage.frequency_id) !== frequencyID) {
          localStorage.setItem('frequency_id', frequencyID);
        }
      }
      this.estimatePrice();
    },

    updateCalendar(e, month, year, direction, moveNextMonth, movePrevMonth) {
      e.preventDefault();
      const date = moment(`${year}-${month}-01`, 'YYYY-M-DD');
      if (direction === 'next') {
        this.loadSpots(date.add(1, 'months'), this.locationID);
        moveNextMonth();
      } else {
        this.loadSpots(date.subtract(1, 'months'), this.locationID);
        movePrevMonth();
      }
    },

    scrollToCalendar() {
      // const elem = this.$el.querySelector('.calendar-wrap').parentElement;
      // window.scroll({
      //   top: window.pageYOffset + elem.getBoundingClientRect().top,
      //   left: 0,
      //   behavior: 'smooth',
      // });
      this.$el.querySelector('.calendar-wrap').parentElement.scrollIntoView({ behavior: 'smooth' });
    },

    // Request dates with spots from Launch27 for 1 month
    loadSpots(date, locationID) {
      if (date && locationID) {
        this.calendarLoading = true;
        this.disabledDates.from = new Date(date);
        this.disabledDates.to = new Date(date);

        const prevMonthDays = moment(date).startOf('month').day() - 1;
        const requestDate = moment(date).subtract(1, 'months').endOf('month').subtract(prevMonthDays, 'days');

        this.$axios.post('/booking/spots', {
          date: `${requestDate.format('YYYY-MM-DD')}`,
          days: 42,
          mode: 'new',
          location_id: locationID,
        })
          .then((response) => {
            if (response && response.data) {
              this.days = response.data;

              if (!this.nextEstimateDate && this.days && this.days[0]) {
                const d = new Date(this.days[0].date);
                if (this.days[0].spots && this.days[0].spots[0]) {
                  const spot = this.days[0].spots[0];
                  d.setHours(spot.hours);
                  d.setMinutes(spot.minutes);
                  const currentDate = d.getDate();
                  const currentMonth = d.getMonth() + 1;
                  const currentHrs = d.getHours();
                  const currentMins = d.getMinutes();
                  const currentSecs = d.getSeconds();

                  this.nextEstimateDate = `${d.getFullYear()}-${currentMonth < 10 ? `0${currentMonth}` : currentMonth}-${currentDate < 10 ? `0${currentDate}` : currentDate}T${currentHrs < 10 ? `0${currentHrs}` : currentHrs}:${currentMins < 10 ? `0${currentMins}` : currentMins}:${currentSecs < 10 ? `0${currentSecs}` : currentSecs}`;
                }
              }

              if (this.days && this.days.length > 0) {
                const freeAM = [];
                const freePM = [];
                const unavailableAM = [];
                const unavailablePM = [];

                this.days.forEach((day) => {
                  if (day.spots && day.spots.length > 0) {
                    day.spots.forEach((spot) => {
                      if (!spot.past) {
                        const dayMoment = moment(day.date);
                        const jsDate = new Date(dayMoment.year(), dayMoment.month(), dayMoment.date());
                        if (spot.free === true) {
                          if (spot.hours === 8) {
                            freeAM.push(jsDate);
                          } else if (spot.hours === 12) {
                            freePM.push(jsDate);
                          }
                        } else if (spot.free === false) {
                          if (spot.hours === 8) {
                            unavailableAM.push(jsDate);
                          } else if (spot.hours === 12) {
                            unavailablePM.push(jsDate);
                          }
                        }
                      }
                    });
                  }
                });

                this.calendarDatesAttribute = [
                  {
                    dot: {
                      backgroundColor: '#00b6bd',
                      borderColor: '#fff',
                      borderWidth: '1px',
                      diameter: '7px',
                    },
                    dates: freeAM,
                  },
                  {
                    dot: {
                      backgroundColor: 'rgba(219, 223, 232)',
                      opacity: 0.5,
                    },
                    dates: unavailableAM,
                  },
                  {
                    dot: {
                      backgroundColor: '#00b6bd',
                      borderColor: '#fff',
                      borderWidth: '1px',
                      diameter: '7px',
                    },
                    dates: freePM,
                  },
                  {
                    dot: {
                      backgroundColor: 'rgba(219, 223, 232)',
                      opacity: 0.5,
                    },
                    dates: unavailablePM,
                  },
                ];
              }
            }
          })
          .catch((error) => {
            // handle error
            console.log(error);
          })
          .then(() => {
            delete this.disabledDates.to;
            delete this.disabledDates.from;
            this.calendarLoading = false;
          });
      }
    },

    checkIfNextMonth(e) {
      if (e && e.inNextMonth === true) {
        const date = moment(`${e.year}-${e.month}-01`, 'YYYY-M-DD');
        this.loadSpots(date, this.locationID);
      }
    },
    // Select a date
    onDateSelect(date) {
      this.date = date;
      const d = `${date.getFullYear()}-${(`0${(date.getMonth() + 1)}`).slice(-2)}-${(`0${date.getDate()}`).slice(-2)}`;

      const selectedday = this.days.find((day) => {
        if (day.date === d) {
          return day;
        }
        return false;
      });

      if (selectedday && selectedday.spots && selectedday.spots.length > 0) {
        this.spots = selectedday.spots;

        if (this.selectedSpot && this.selectedSpot.hours) {
          const s = this.spots.find(spot => spot.hours === this.selectedSpot.hours);
          if (s && s.free === false) {
            this.selectedSpot = null;
          } else {
            this.selectedSpot = s;
          }
        }
      } else {
        this.spots = [];
      }
    },

    // Select a spot
    selectSpot(index) {
      if (index >= 0) {
        this.selectedSpot = this.spots[index];

        if (this.showErrors.indexOf('spot') > -1) {
          this.showErrors.splice(this.showErrors.indexOf('spot'));
        }
      }
    },

    // Return is spot selected in the list
    isSelectedSpot(spot) {
      if (this.selectedSpot && spot) {
        if (spot.hours === this.selectedSpot.hours) {
          return true;
        }
        return false;
      }
      return false;
    },

    loadCustomFields() {
      if (this.form.custom_fields && this.form.custom_fields.length === 0) {
        this.customFieldsLoading = true;
        this.$axios.get('/booking/custom-fields')
          .then((response) => {
            if (response && response.data && response.data.length > 0) {
              this.customFields = response.data;
              this.form.custom_fields = [];
              response.data.forEach((field) => {
                this.form.custom_fields.push({
                  id: field.id,
                  value: null,
                });
                this.selectedCustomFields[field.id] = null;
              });
            }
          })
          .catch((error) => {
            // handle error
            console.log(error);
          })
          .then(() => {
            this.customFieldsLoading = false;
          });
      }
    },

    setCustomFieldButton(customFieldID, optionID) {
      if (customFieldID && optionID) {
        const currentVal = { ...this.selectedCustomFields };
        currentVal[customFieldID] = optionID;
        this.selectedCustomFields = currentVal;

        const foundIndex = this.form.custom_fields.findIndex(cf => cf.id === customFieldID);

        if (foundIndex >= 0) {
          if (this.form.custom_fields[foundIndex]) {
            this.form.custom_fields[foundIndex].value = optionID.toString();
          }
        } else {
          this.form.custom_fields.push({
            id: customFieldID,
            optionID: optionID.toString(),
          });
        }

        if (this.showErrors.indexOf('customField') > -1) {
          this.showErrors.splice(this.showErrors.indexOf('customField'));
        }

        if (customFieldID === 265) {
          if (optionID === 30) {
            const foundExtraIndex = this.selectedExtras.findIndex(extra => extra.id === this.keyboxID);
            if (foundExtraIndex < 0) {
              this.selectedExtras.push({
                id: this.keyboxID,
                quantity: 1,
              });
            }
            this.showAccessMore = false;
          } else {
            const foundExtraIndex = this.selectedExtras.findIndex(extra => extra.id === this.keyboxID);
            if (foundExtraIndex >= 0) {
              this.selectedExtras.splice(foundExtraIndex, 1);
            }
            if (optionID === 29 || optionID === 31) {
              this.showAccessMore = true;
            } else {
              this.showAccessMore = false;
            }
          }
          this.form.services[0].extras = this.selectedExtras;
          this.estimatePrice();
        }
      }
    },
    setCustomField(e, customFieldID) {
      if (customFieldID) {
        const { value } = e.target;
        this.selectedCustomFields[customFieldID] = value;

        const foundIndex = this.form.custom_fields.findIndex(cf => cf.id === customFieldID);

        if (foundIndex >= 0) {
          if (this.form.custom_fields[foundIndex]) {
            this.form.custom_fields[foundIndex].value = value.toString();
          }
        } else {
          this.form.custom_fields.push({
            id: customFieldID,
            value: value.toString(),
          });
        }
      }
    },

    isSelectedCustomField(customFieldID, optionID) {
      if (this.selectedCustomFields && optionID && this.selectedCustomFields[customFieldID] === optionID) {
        return true;
      }
      return false;
    },

    clearPayment() {
      this.paymentCompleted = false;
    },

    setPayment(token) {
      this.form.stripe_token = token;
      this.paymentCompleted = true;
      this.completeBooking();
    },


    toggleComment(e) {
      e.preventDefault();
      this.showComment = !this.showComment;
    },

    openCalendar() {
      const control = this.$refs.dp.$el.querySelector('.form-control');
      control.focus();
      control.click();
    },

    discountKeyPress(e) {
      e.preventDefault();

      this.estimatePrice();
    },

    // Estimate price
    estimatePrice() {
      if ((this.form.service_date || this.nextEstimateDate) && this.form.location_id && this.form.frequency_id && this.form.services && this.form.services.length > 0) {
        this.estimateLoading = true;

        const data = {
          service_date: this.form.service_date || this.nextEstimateDate,
          location_id: this.form.location_id,
          frequency_id: this.form.frequency_id,
          services: this.form.services,
        };

        // if (this.form.user.email) {
        //   data.email = this.form.user.email;
        // }

        let discountOld = 0;
        if (this.estimate && this.estimate.discount_amount) {
          discountOld = this.estimate.discount_amount;
        }

        if (this.form.discount_code) {
          data.discount_code = this.form.discount_code;
          this.discountCodeLoading = true;
        }

        this.$axios.post('/booking/estimate-pricing', data)
          .then((response) => {
            if (response && response.data) {
              this.estimate = response.data;

              if (this.estimate.discount_amount !== discountOld) {
                this.discountCodeAmount = this.estimate.discount_amount - discountOld;
              } else {
                this.form.discount_code = null;
              }
            }
          })
          .catch((error) => {
            // handle error
            console.log(error);
          })
          .then(() => {
            this.estimateLoading = false;
            this.discountCodeLoading = false;
          });
      }
    },

    deleteDiscountCode() {
      this.discountCodeAmount = 0;
      this.form.discount_code = '';
      this.estimatePrice();
    },

    loadPayment(e) {
      this.isLoading = e;
    },

    toggleConfirm(val) {
      this.confirm = val;
      if (this.showErrors.indexOf('confirm') > -1) {
        this.showErrors.splice(this.showErrors.indexOf('confirm'));
      }
    },

    toggleQuestionsModal(e, outside) {
      // e.preventDefault();
      if (outside && e.target.classList.contains('modal') && !e.target.classList.contains('modal-content') && !e.target.classList.contains('modal-header')) {
        document.body.classList.toggle('noscroll');
        this.showQuestionsModal = !this.showQuestionsModal;
      } else if (!outside) {
        const id = e.target.href && e.target.href.split('#') ? e.target.href.split('#')[1] : '';
        document.body.classList.toggle('noscroll');
        this.showQuestionsModal = !this.showQuestionsModal;

        if (id !== '') {
          setTimeout(() => {
            this.question = id;
            const index = Number.parseInt(id.substring(1), 10);
            let height = 0;

            for (let i = 1; i < index; i += 1) {
              height += this.$el.querySelector(`#q${i}`).offsetHeight;
            }

            this.$el.querySelector('.questions-modal .modal-content').scrollTop = height;
          }, 10);
        }
      }
    },

    book() {
      this.showErrors = [];
      this.lauchErrors = [];
      let proceed = true;
      let scrollTo = '';

      if (!this.selectedServiceID || !this.form.frequency_id) {
        proceed = false;
        if (!this.selectedServiceID) {
          this.showErrors.push('service');

          if (scrollTo === '') {
            scrollTo = 'service';
          }
        }
        if (!this.form.frequency_id) {
          this.showErrors.push('frequency');

          if (scrollTo === '') {
            scrollTo = 'frequency';
          }
        }
      }
      if (!this.selectedSpot || this.customFieldRequired) {
        proceed = false;
        if (this.customFieldRequired) {
          this.showErrors.push('customField');

          if (scrollTo === '') {
            scrollTo = 'access';
          }
        }

        if (!this.selectedSpot) {
          this.showErrors.push('spot');

          if (scrollTo === '') {
            scrollTo = 'spot';
          }
        }
      }
      if (this.$v.$invalid) {
        proceed = false;
        this.$v.$touch();

        this.showErrors.push('address');
        if (scrollTo === '') {
          scrollTo = 'address';
        }
      }
      if (!this.canBook) {
        proceed = false;
      }
      if (!this.confirm) {
        proceed = false;
        this.showErrors.push('confirm');
      }

      if (proceed) {
        if (this.form.payment_method === 'stripe') {
          this.paymentCompleted = false;
          if (this.form.stripe_token !== '') {
            this.paymentCompleted = true;
            this.completeBooking();
          } else if (!this.paymentCompleted) {
            this.$refs.payment.pay();
          }
        } else if (this.form.payment_method === 'cash') {
          this.paymentCompleted = true
          this.completeBooking()
        }
      } else if (!proceed && scrollTo !== '') {
        this.scrollToError(scrollTo);
      }
    },

    completeBooking() {
      this.isLoading = true;
      if (this.paymentCompleted === true && ((this.form.payment_method === 'stripe' && this.form.stripe_token !== '') || this.form.payment_method === 'cash')) {
        const form = { ...this.form };
        if (form.apt !== '') {
          form.address = `${form.address} ${form.apt}`;
        }

        if (this.form.payment_method === 'cash'){
          delete form.stripe_token
        }

        this.$axios.post('/booking', form)
          .then((response) => {
            if (response && response.data) {
              if (response.data.id) {
                this.goToStep(1);

                if (response.data.ga_transaction) {
                  const transaction = response.data.ga_transaction;

                  if (response.data.ga_item) {
                    const item = response.data.ga_item;
                    window.dataLayer = window.dataLayer || [];

                    if (window.dataLayer) {
                      window.dataLayer.push({
                        event: 'transaction',
                        ecommerce: {
                          purchase: {
                            actionField: {
                              id: transaction.id,
                              affiliation: transaction.affilation,
                              revenue: transaction.revenue,
                            },
                            products: [{
                              name: item.name,
                              id: item.id,
                              price: item.price,
                              quantity: item.quantity,
                            }],
                          },
                        },
                      });
                    }
                  }
                }
              }
            }
          })
          .catch((error) => {
            if (error && error.response && error.response.data) {
              Object.keys(error.response.data).forEach((key) => {
                error.response.data[key].forEach((err) => {
                  this.lauchErrors.push(err.message);
                });
              });
            } else {
              console.log(error);
            }
          })
          .then(() => {
            this.paymentCompleted = false;
            this.isLoading = false;
          });
      }
    },

    bookAgain() {
      this.form.service_date = null;
      this.selectedDate = null;
      this.selectedSpot = null;
      this.spots = [];
      this.form.discount_code = null;
      this.discountCodeAmount = 0;
      this.goToStep(0);
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/main.scss";
</style>

